<template>
  <v-flex xs12 :md3="!fullscreen" class="pa-6">
    <v-card elevation="24" dark color="#555">
      <v-system-bar lights-out></v-system-bar>
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="false"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide, i) in purchase.item.images" :key="i">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-progress-linear
        height="27"
        color="green accent-3"
        :buffer-value="purchase.total_percent_gained"
        stream
        dark
        >
            <span class="fonte"> {{ purchase.current_status }} </span>
        </v-progress-linear>
      <v-list three-line color="#555" dark dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte" style="font-size: 14pt;">{{
              investment.title
            }}</v-list-item-title>
            <v-list-item-title class="fonte orange--text">
              Ganho total:
              {{ purchase.item.total_percent_gain }}%</v-list-item-title
            >
            <v-list-item-subtitle class="fonte font-weight-thin">{{
              purchase.item.description
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["investment", "fullscreen", "priceless", "purchase"]
};
</script>
